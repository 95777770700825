/* @import '~antd/dist/antd.css'; */

@import './demo.css';
.custom-selects .ant-select-selection,
.custom-selects .ant-select-selection__rendered {
    border: none;
    height: 100%;
    line-height: 42px;
}


/* .custom-selects .ant-select-selection__rendered {} */

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: transparent;
    top: 0;
    z-index: 8;
    left: 0;
}

input {
    height: 48px !important;
    border-radius: 25px !important;
    padding: 5px 15px !important;
}

input-workspace {
    height: 48px !important;
    border-radius: 25px 0px 0px 25px !important;
    padding: 5px 15px !important;
}

ant-input-group-addon {
    height: 48px !important;
    border-radius: 25px !important;
    padding: 5px 15px !important;
}

.privacy {
    padding: 1em;
    margin: 8px;
    color: #4c4c4c;
    font-size: 16px;
}

.privacy p {
    margin-bottom: 7px;
}

.privacy a {
    color: #1e00ee;
}

.privacy h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    color: #000;
}

.privacy strong {
    color: #000;
    font-weight: bold;
}

.privacy ol,
.privacy ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

section.contact .colunm .acheckin,
section.contact .colunm label {
    top: 25px !important;
}


/* .bgCover-demo:before { */


/* top: -2px; */


/* } */


/* .bgCover-demo:after { */


/* top: -1px; */


/* } */

.top-demo {
    padding: 8px 0;
}

.download {
    text-align: center;
}

.downloadLink {
    display: inline-block;
    margin: 10px 5px;
    text-align: center;
}

.downloadText {
    display: block;
    text-align: center;
    color: #0FA44A;
}

.downloadImg {
    width: 100%;
    max-width: 120px;
    margin: 0 auto;
    display: block;
}

@media (max-width: 767px) {
    header .icon-menu {
        margin-top: 20px;
        margin-right: 10px;
    }
}

.menu-header {
    position: fixed;
    top: 0;
    width: 100%;
    background: #0FA44A;
    z-index: 999;
}

.menu-scroll {
    box-shadow: 0px 5px 30px 0px #00000026;
}


/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) and (max-width: 767.98px) {
    .heigth-col {
        height: 75px;
    }
}

.image-partner {
    width: 180px !important;
}

.col-margin-left {
    margin-right: -50px !important;
    margin-left: -50px !important;
    margin-bottom: 20px;
}

.bg-free-consultation {
    background: url('../src/assets/images/homev1/bg_free_consultation.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.title-free-consultation {
    font-weight: 700;
    font-size: 36px;
    line-height: 140%;
    color: #FFFFFF;
    padding-top: 80px;
}

.content-free-consultation {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
    margin-top: 24px;
}

.input-antd {
    background: #FFFFFF;
    border: 1px solid #D9D9D9!important;
    border-radius: 6px!important;
    -webkit-border-radius: 6px!important;
    -moz-border-radius: 6px!important;
    -ms-border-radius: 6px!important;
    -o-border-radius: 6px!important;
    margin-bottom: 16px!important;
    height: 40px!important;
}

.mb-16 {
    margin-bottom: 16px!important;
}

.ant-select-selector {
    border-radius: 6px!important;
}

.button-registration {
    font-weight: 600!important;
    font-size: 14px!important;
    line-height: 22px!important;
    color: #FFFFFF!important;
    background: #FA8C16!important;
    border-radius: 8px!important;
    -webkit-border-radius: 8px!important;
    -moz-border-radius: 8px!important;
    -ms-border-radius: 8px!important;
    -o-border-radius: 8px!important;
    border: none !important;
    padding: 0px 44px!important;
    height: 40px!important;
}

.form-register {
    background: #FFFFFF;
    border-radius: 16px;
    padding: 37px 30px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.16);
}

.title-register {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #262626;
    margin-bottom: 24px;
}

.ant-form-item-explain-error {
    color: #ff4d4f;
    margin-top: -10px;
}

.padding-60 {
    padding: 60px 0;
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .title-free-consultation {
        font-size: 30px;
        padding-top: 20px;
    }
}

@media (max-width: 767.98px) {
    .title-free-consultation {
        padding-top: 0px;
        font-size: 24px;
    }
    .content-free-consultation {
        font-size: 16px;
        margin-bottom: 20px;
    }
}

.box-info {
    padding: 15px;
    -webkit-box-shadow: 0 0 5px 0 rgb(0 0 0 / 16%);
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 16%);
    border-radius: 5px;
}

.call-for-ip {
    display: flex;
    background: #f15822;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    position: fixed;
    left: 18pt;
    bottom: 24px;
    justify-content: center;
    align-items: center;
    z-index: 999;
    -webkit-animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
    animation: phonering-alo-circle-img-anim 1s infinite ease-in-out;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

.call-for-ip:before {
    position: absolute;
    content: " ";
    z-index: -1;
    top: -10px;
    left: -10px;
    background-color: #f15822;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    opacity: .6;
    -webkit-animation: pulse 1.2s infinite ease-in-out;
    animation: pulse 1.2s infinite ease-in-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.call-for-ip .border-call a img {
    width: 35px;
}

.call-for-ip:after {
    content: " ";
    z-index: -1;
    width: 120px;
    height: 120px;
    top: -30px;
    left: -30px;
    position: absolute;
    background-color: transparent;
    border-radius: 100%!important;
    border: 2px solid #fd9875;
    opacity: .1;
    -webkit-animation: phonering-alo-circle-anim 1.2s infinite ease-in-out;
    animation: phonering-alo-circle-anim 1.2s infinite ease-in-out;
    transition: all .5s;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}

@keyframes pulse {
    0% {
        -webkit-transform: rotate(0) scale(0.7) skew(1deg);
        opacity: .2
    }
    50% {
        -webkit-transform: rotate(0) scale(1) skew(1deg);
        opacity: .2
    }
    100% {
        -webkit-transform: rotate(0) scale(0.7) skew(1deg);
        opacity: .2
    }
}

@keyframes phonering-alo-circle-anim {
    0% {
        -webkit-transform: rotate(0) scale(0.5) skew(1deg);
        -webkit-opacity: .1
    }
    30% {
        -webkit-transform: rotate(0) scale(0.7) skew(1deg);
        -webkit-opacity: .5
    }
    100% {
        -webkit-transform: rotate(0) scale(1) skew(1deg);
        -webkit-opacity: .1
    }
}

@keyframes phonering-alo-circle-img-anim {
    0% {
        -webkit-transform: rotate(0) scale(1) skew(1deg)
    }
    10% {
        -webkit-transform: rotate(-25deg) scale(1) skew(1deg)
    }
    20% {
        -webkit-transform: rotate(25deg) scale(1) skew(1deg)
    }
    30% {
        -webkit-transform: rotate(-25deg) scale(1) skew(1deg)
    }
    40% {
        -webkit-transform: rotate(25deg) scale(1) skew(1deg)
    }
    50% {
        -webkit-transform: rotate(0) scale(1) skew(1deg)
    }
    100% {
        -webkit-transform: rotate(0) scale(1) skew(1deg)
    }
}

.custom-modal-home .ant-modal-content {
    border-radius: 16px;
}

.custom-modal-home .ant-modal-content .ant-modal-body {
    padding: 0
}

.custom-modal-home .ant-modal-confirm-body .ant-modal-confirm-content {
    margin-top: 0;
}

.img-modal-home {
    width: 100%;
    border-radius: 16px;
}

.input-antd-modal {
    margin-bottom: 0!important;
}

.modal-padding {
    padding: 30px;
}

.title-modal-home {
    font-weight: 700;
    font-size: 22px;
    line-height: 130%;
    text-align: center;
    color: #0FA44A;
    margin-bottom: 24px;
}

.bg-card-download {
    background: #FAFAFA;
}

.help-dowload {
    margin-bottom: 90px;
}

.title-help-download {
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #262626;
    margin: 16px 0px;
}

.content-help-download {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #8C8C8C;
    margin-bottom: 12px;
}

.footer-bottom {
    background-color: #FFFFFF;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    color: #262626;
}

.title-footer {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8C8C8C;
}

.text-right-footer {
    color: #262626;
}

.title-category {
    margin-top: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    margin-bottom: 30px;
}

.padding-top-87 {
    padding-top: 87px;
}

.header-contact {
    min-height: 600px;
}

.border-radius-16 {
    border-radius: 16px;
    margin-bottom: 70px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.hrm-min-height {
    min-height: 650px;
}

.banner-hrm {
    display: flex;
}

.banner-hrm .btnHeader_Fill {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 11px 16px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
}

.color-trial {
    background: #FFB016;
    border: 1px solid #FFB016!important;
}

.grecaptcha-badge {
    display: none!important;
}

@media (max-width: 575.98px) {
    .home-v1 .featureHome .infosectionright {
        margin-top: 40px;
    }
    .home-v1 .partner {
        padding: 30px 0px!important;
    }
    .home-v1 .featureHome {
        padding: 0!important;
    }
    .home-v1 .featureHome .infosection {
        padding-top: 30!important;
    }
    header .logo {
        padding-top: 0!important;
    }
    .home-v1 .header-top {
        padding: 20px;
    }
    header .icon-menu img {
        width: 30px!important;
    }
    .home-v1 header .icon-menu {
        margin-top: 5px!important;
    }
    .inner .featurehot {
        text-align: left!important;
    }
    .block-feature-1 {
        padding-top: 30px!important;
    }
    .title-block {
        margin-bottom: 10px!important;
    }
    .block-feature .section {
        padding: 12px!important;
        margin: 10px 0px!important;
    }
    .title-feature-page {
        margin-bottom: 20px!important;
    }
    .block-feature .section1 {
        padding: 12px!important;
        margin: 10px 0px!important;
    }
    .block-Gray {
        padding: 30px 0px 0px!important;
    }
    .home-v1 .title-item {
        text-align: left!important;
    }
    .home-v1 .content-item {
        text-align: left!important;
    }
    .home-v1 .feature .infosection {
        padding-top: 0!important;
    }
}

@media (min-width: 768px) {
    .title-block {
        margin: 32px 0px!important;
    }
    .padding-top {
        padding: 1px!important;
    }
}

@media (min-width: 1200px) {
    .feature-home .bgsection {
        height: -webkit-fill-available!important;
    }
}

.slideFeature {
    margin-top: 32px;
}

@media (max-width: 400px) {
    .iphone {
        margin: 0 auto;
    }
    .slideFeature .innerslide {
        left: 50%!important;
        transform: translate(-50%, 0%)!important;
    }
    .slideFeature {
        margin-bottom: 32px;
    }
}

.itemSlider-hrm {
    padding: 0!important;
}

@media (max-width: 404px) {
    .banner-hrm .btnHeader_Fill {
        padding: 10px;
        font-size: 13px;
    }
}

.tab-feautre-fixed {
    position: fixed;
    top: 70px;
    z-index: 999;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    border-radius: 0;
}