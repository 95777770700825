@import '~react-owl-carousel2/lib/styles.css';
.home-v1 .header-middle .txt h6,
.home-v1 .header-middle .txt h1,
.home-v1 .feature .slideinfo h5 {
    color: #fff;
}

.home-v1 .video-demo {
    padding: 50px 0;
}

.home-v1 .video-demo .title-block {
    margin-bottom: 35px;
}

.home-v1 .video-demo .title-block span.line {
    margin: 0;
    margin-top: 17px;
}

.home-v1 .video-demo .readmore {
    font-size: 15px;
    font-weight: bold;
    color: #000;
    vertical-align: middle;
    margin-top: 20px;
    margin-bottom: 15px;
}

.home-v1 .video-demo .readmore img {
    margin-left: 15px;
}

.home-v1 .modal-video .modal-content {
    height: 100vh;
    background: transparent;
}

.home-v1 .modal-video .close {
    position: absolute;
    right: -20px;
    top: -15px;
    z-index: 9;
    opacity: 1;
    width: 50px;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9C9B9F !important;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #9C9B9F !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #9C9B9F !important;
}

.has-error .ant-form-explain,
.has-error .ant-form-split {
    margin-top: 15px;
    text-align: left;
}

.home-v1 .btn-submit {
    padding: 0 25px;
    height: 45px;
    line-height: 43px;
    border: 1px solid #449d47;
    border-radius: 25px;
    font-size: 15px;
}

.home-v1 {
    font-size: 15px;
    font-family: 'HelveticaNeue', Arial, sans-serif;
    overflow-x: hidden;
}

.home-v1 .ant-form-item {
    margin: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    /* vertical-align: middle !important; */
    font-weight: normal;
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    transition: all 0.25s ease;
}

a:hover {
    text-decoration: none;
}

.bottom-feature {
    padding-bottom: 16px;
}

@media screen and (max-width: 1024px) {
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    input[type="password"],
    input[type="phone"] {
        font-size: 16px !important;
        ;
        box-shadow: none !important;
        outline: none;
    }
}

::-moz-selection {
    /* Code for Firefox */
    color: #fff;
    background: #0fa44a;
}

::selection {
    color: #fff;
    background: #0fa44a;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('src/assets/fonts/HelveticaNeue/HelveticaNeue-Regular.eot?#iefix') format('embedded-opentype'), url('src/assets/fonts/HelveticaNeue/HelveticaNeue-Regular.otf') format('opentype'), url('src/assets/fonts/HelveticaNeue/HelveticaNeue-Regular.woff') format('woff'), url('src/assets/fonts/HelveticaNeue/HelveticaNeue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue-Bold';
    src: url('src/assets/fonts/HelveticaNeue-Bold/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'), url('src/assets/fonts/HelveticaNeue-Bold/HelveticaNeue-Bold.otf') format('opentype'), url('src/assets/fonts/HelveticaNeue-Bold/HelveticaNeue-Bold.woff') format('woff'), url('src/assets/fonts/HelveticaNeue-Bold/HelveticaNeue-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue-Medium';
    src: url('src/assets/fonts/HelveticaNeue-Medium/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'), url('src/assets/fonts/HelveticaNeue-Medium/HelveticaNeue-Medium.otf') format('opentype'), url('src/assets/fonts/HelveticaNeue-Medium/HelveticaNeue-Medium.woff') format('woff'), url('src/assets/fonts/HelveticaNeue-Medium/HelveticaNeue-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body {
    font-size: 15px;
    font-family: 'HelveticaNeue', Arial, sans-serif;
    line-height: 1.7;
    position: relative;
    font-weight: normal;
    padding: 0;
    margin: 0;
    color: #54555a;
    font-weight: 500;
    background-color: #ffffff;
}

.modal-open {
    padding: 0 !important;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1170px;
    }
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background-color: #0FA44A;
}

.show-mb {
    display: none;
}

@media (max-width: 576px) {
    .show-mb {
        display: block;
    }
}

.hidden-mb {
    display: block;
}

@media (max-width: 576px) {
    .hidden-mb {
        display: none;
    }
}

.invalid-feedback {
    display: block;
}

a:hover,
a:focus {
    color: #0fa44a;
}

.owl-theme .owl-nav [class*=owl-],
.owl-theme .owl-nav [class*=owl-]:hover {
    background: none;
    padding: 0;
}

.owl-next,
.owl-prev {
    position: absolute;
    top: 50%;
}

.owl-next {
    right: 0;
    transform: rotate(180deg);
}

.owl-prev {
    left: 0;
}

header {
    padding: 0px 0 0px;
    background: #0fa44a;
    color: #fff;
    min-height: 755px;
}

header .logo {
    padding-top: 15px;
}

header a {
    color: #fff;
    display: block;
}

header a:hover,
header a:focus,
header a.active {
    color: #517820;
}

header ul.list-inline a {
    display: inline-block;
    padding: 22px 10px;
    font-weight: normal;
    text-transform: uppercase;
    position: relative;
    font-size: 14px;
}

header ul.list-inline a:before {
    content: "";
    width: 40%;
    background: #517820;
    height: 2px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%) scale(0);
    transition: all 0.3s ease;
    display: none;
}

header ul.list-inline a:hover:before,
header ul.list-inline a.active:before {
    transform: translateX(-50%) scale(1.1);
}

header .icon-menu {
    display: none;
}

.header-top {
    position: relative;
}

.home-v1 .header-middle {
    position: relative;
}

.header-middle .txt {
    padding-top: 35px;
}

.header-middle .txt h6,
.header-middle .txt h1 {
    /* font-family: 'HelveticaNeue-Bold', Arial, sans-serif; */
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 25px;
    font-weight: bold;
    text-align: left;
}

.header-middle .txt p {
    font-size: 16px;
    margin-bottom: 30px;
    color: rgba(255, 255, 255, 0.9);
}

.header-middle .txt img {
    left: 0;
    position: absolute;
}

.header-middle .img-right {
    position: absolute;
    right: 0;
}

.header-middle .btn {
    padding-left: 50px;
    padding-right: 50px;
    background: #fff;
    border-color: #fff;
    color: #fff;
    /* font-family: 'HelveticaNeue-Bold', Arial, sans-serif; */
    text-transform: uppercase;
    font-weight: bold;
}

.header-middle .btn a {
    color: #449d47;
}

.download-git {
    padding: 40px 0 50px;
}

.download-git h6 {
    color: #2d2e30;
    font-family: 'HelveticaNeue-Medium';
    font-size: 18px;
    margin-bottom: 30px;
}

.btn {
    padding: 0 25px;
    height: 45px;
    line-height: 43px;
    color: #0fa44a;
    border: 1px solid #0fa44a;
    border-radius: 25px;
    font-size: 15px;
    box-shadow: none;
}

.btn.cl1 {
    background: #0fa44a;
    color: #fff;
}

.btn img {
    vertical-align: middle;
    margin-right: 8px;
}

.btn.focus,
.btn:focus {
    box-shadow: none;
}

main {
    position: relative;
    z-index: 9;
}

.title-block {
    font-size: 30px;
    line-height: 30px;
    color: #2d2e30;
    font-family: 'HelveticaNeue-Bold';
    margin-bottom: 55px;
}

.title-block.no-bf span:before {
    display: none;
}

.title-block span {
    position: relative;
    z-index: 9;
    display: inline-block;
}

.title-block span:before {
    content: "";
    width: 85px;
    height: 12px;
    position: absolute;
    background: url(src/assets/images/img-before-title.png) no-repeat top left;
    background-size: 100% 100%;
    z-index: -1;
    left: 50%;
    bottom: -25px;
    transform: translateX(-50%);
}

.title-block p {
    font-size: 17px;
    color: #000;
    margin-top: 45px;
    max-width: 800px;
    margin: 0 auto;
}

.grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    grid-gap: 80px 30px;
}

.icon-item {
    margin-bottom: 20px;
    text-align: center;
}

.title-item {
    font-size: 18px;
    line-height: 20px;
    color: #2d2e30;
    font-family: 'HelveticaNeue-Bold';
    margin-bottom: 15px;
    text-align: center;
}

.content-item {
    color: #54555a;
    padding: 0 0px;
    font-size: 13px;
    text-align: center;
}

.content-item br {
    display: none;
}

.why-use {
    padding: 75px 0 65px;
    background: #f5f5f5;
}

.why-use .title-block {
    margin-bottom: 110px;
}

section.contact {
    background: #f5f5f5;
    padding: 145px 0 70px;
}

section.contact .content-block {
    width: 70%;
    margin: 0 auto;
}

section.contact .btn-submit {
    color: #fff;
    background: #0fa44a;
    border-radius: 5px;
}

section.contact .btn-submit a {
    color: #fff;
}

section.work {
    padding: 70px 0 100px;
}

section.work .line-bottom-right:after {
    content: "";
    width: 1px;
    border: 1px dashed #0fa44a;
    right: -105px;
    height: 220px;
    position: absolute;
    top: 50%;
}

section.work .line-bottom-left:after {
    content: "";
    width: 1px;
    border: 1px dashed #0fa44a;
    left: -95px;
    height: 220px;
    position: absolute;
    top: 50%;
}

section.work .line-top-right:after {
    content: "";
    width: 1px;
    border: 1px dashed #0fa44a;
    right: -105px;
    height: 90px;
    position: absolute;
    bottom: 50%;
}

section.work .line-top-left:after {
    content: "";
    width: 1px;
    border: 1px dashed #0fa44a;
    left: -95px;
    height: 90px;
    position: absolute;
    bottom: 50%;
}

section.work .line-right:before {
    content: "";
    height: 1px;
    width: 105px;
    border: 1px dashed #0fa44a;
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
}

section.work .line-left:before {
    content: "";
    height: 1px;
    width: 95px;
    border: 1px dashed #0fa44a;
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
}

section.work .line-top:before {
    content: "";
    height: 80px;
    bottom: 100%;
    width: 1px;
    border: 1px dashed #0fa44a;
    position: absolute;
    left: 50%;
}

section.work .line-bottom:before {
    content: "";
    height: 80px;
    top: 100%;
    width: 1px;
    border: 1px dashed #0fa44a;
    position: absolute;
    left: 50%;
}

section.work .content-block .item {
    position: relative;
    padding-bottom: 10px;
}

section.work .content-item br {
    display: block;
}

section.work .title-block {
    margin-bottom: 80px;
}

section.work .title-item {
    display: inline-block;
    height: 44px;
    background-color: #e9f6ea;
    border-radius: 5px;
    text-align: center;
    width: 200px;
    line-height: 44px;
    position: relative;
}

section.work .text-right {
    padding-right: 110px;
}

section.work .text-right ul li {
    padding-right: 25px;
}

section.work .text-right ul li:before {
    content: "";
    position: absolute;
    right: 0;
    top: 10px;
    background: #999999;
    border-radius: 100%;
    height: 6px;
    width: 6px;
}

section.work .text-left {
    padding-left: 110px;
}

section.work .text-left ul li {
    padding-left: 25px;
}

section.work .text-left ul li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    background: #999999;
    border-radius: 100%;
    height: 6px;
    width: 6px;
}

section.work .row-img {
    margin: 60px 0;
}

section.work ul li {
    margin-bottom: 10px;
    position: relative;
}

section.video {
    padding: 75px 0 85px;
    background: url(src/assets/images/bg-row.jpg) no-repeat top left;
    background-size: cover;
    position: relative;
    min-height: 610px;
}

section.video .inner {
    position: relative;
}

section.video .title-block {
    color: #fff;
}

section.video .title-block span:before {
    background: url(src/assets/images/img-before-title1.png) no-repeat top left;
    background-size: 100% 100%;
}

section.video .video-dm {
    border: 8px solid #fff;
    background-color: #000;
    width: 90%;
    margin: 0 auto;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    box-shadow: 0 0 1px #ebebeb;
}

section.video iframe {
    width: 100%;
}

.icon-menu {
    display: none;
}

.form-control {
    height: 48px;
    color: #000;
    background: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 6px;
    font-size: 13px;
}

.form-control:focus {
    box-shadow: none;
    border-color: #0fa44a;
}

footer .copyright {
    background: #232323;
    font-size: 13px;
    padding: 15px 0;
    color: #fff;
}

footer .footer-middle {
    padding: 50px 0;
    font-size: 13px;
    color: #2d2e30;
    font-family: 'HelveticaNeue-Medium';
}

footer .footer-middle a {
    color: #2d2e30;
}

footer .footer-middle a:hover {
    color: #0fa44a;
}

footer .footer-middle a img {
    vertical-align: middle;
    margin-right: 10px;
}

footer .footer-middle ul li {
    padding-bottom: 3px;
}

footer .footer-middle .list-footer-dl {
    margin-top: 10px;
}

footer .footer-middle .list-footer-dl li {
    padding-bottom: 7px;
}

footer .footer-middle .logo-footer {
    color: #808080;
    margin-bottom: 15px;
}

.shop {
    background: #f5f5f5;
}

@media (min-width: 1200px) {
    .shop .form-control {
        border-radius: 25px;
        font-size: 13px;
        text-indent: 15px;
    }
}

.shop .form-control {
    border-radius: 25px;
}

.shop .font2 {
    font-family: 'HelveticaNeue-Bold';
}

.shop .fontbold {
    font-weight: bold;
}

.shop .cl1 {
    color: #0fa44a;
}

.shop img {
    max-width: 100%;
}

.shop header {
    background: #0fa44a url('src/assets/images/bg-cover.jpg') no-repeat top left / cover;
}

.shop .header-middle {
    margin-top: 45px;
    position: relative;
}

.shop .header-middle .txt {
    padding-top: 50px;
    margin-bottom: 100px;
}

.shop .header-middle .txt p {
    font-size: 15px;
}

.shop .info .pd {
    padding: 75px 0;
}

.shop .info .pd-left {
    padding-left: 100px;
}

.shop .info .pd-right {
    padding-right: 100px;
}

.shop .info .title-block {
    margin-bottom: 25px;
    font-size: 36px;
    line-height: 36px;
    margin-top: 130px;
}

.shop section.price {
    min-height: 300px;
    background: none;
    position: relative;
    background: #f5f5f5;
    padding-bottom: 50px;
}

.shop section.price .bonus {
    color: #000000;
}

.shop section.price .bonus span:before {
    display: none;
}

.shop section.price:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 350px;
    background: url(src/assets/images/bg-row.jpg) no-repeat top left;
    background-size: cover;
}

.shop section.price .content-block {
    background: #fff;
    border-radius: 6px;
    position: relative;
    z-index: 9;
    width: 90%;
    margin: 0 auto;
}

.shop section.price .block-bonus {
    width: 90%;
    margin: 0 auto;
    font-size: 14px;
}

.shop section.price .block-bonus .table-responsive {
    overflow-x: inherit;
}

.shop section.price .block-bonus .title-block {
    padding: 35px 0 0;
}

.shop section.price .block-bonus thead {
    background: #0fa44a;
}

.shop section.price .block-bonus thead th {
    padding: 15px 15px!important;
    font-size: 14px;
    color: #fff;
    text-align: left;
    padding-left: 120px !important;
}

.shop section.price .block-bonus tbody {
    border-top: none;
}

.shop section.price .block-bonus tbody td {
    text-align: left;
    padding-left: 120px;
    border: 1px solid #dee2e6;
}

.shop section.price .table thead th {
    border: none;
    vertical-align: middle;
    padding: 40px 30px 35px;
}

.shop section.price .table thead th.col1 {
    padding: 25px 60px 50px;
}

.shop section.price .table thead th .cl1 {
    font-size: 13px;
}

.shop section.price tbody {
    border-top: 1px solid #dee2e6;
    font-size: 14px;
}

.shop section.price tbody td {
    text-align: center;
    border: none;
}

.shop section.price tbody td.col1 {
    padding-left: 60px;
    text-align: left;
}

.shop section.price .font2 {
    color: #000000;
    margin-bottom: 3px;
}

.shop section.price .btn {
    margin-top: 25px;
    color: #fff;
}

.shop section.price .btn:hover {
    filter: brightness(110%);
}

.shop section.price .btn.btn1 {
    background: #0fa44a;
    border-color: #0fa44a;
}

.shop section.price .btn.btn2 {
    background: #feca15;
    border-color: #feca15;
}

.shop section.contact {
    padding-top: 70px;
    background: #fff;
    padding-bottom: 55px;
}

.shop section.contact .btn-submit {
    border-radius: 25px;
}

.shop section.info {
    background: #fff;
}

.faq {
    color: #54555a;
}

.faq .border-bottom {
    border-bottom: 1px solid #d9d9d9;
}

.faq .blockfaq a {
    color: #2d2e30;
}

.faq .blockfaq a:hover,
.faq .blockfaq a:focus {
    color: #0fa44a;
}

.faq .blocktitle {
    border-bottom: 1px solid #d9d9d9;
    font-size: 25px;
    line-height: 25px;
    color: #2d2e30;
    font-family: 'HelveticaNeue-Bold';
}

.faq header {
    min-height: inherit;
}

.faq .title-block {
    margin-bottom: 0px;
    padding-bottom: 30px;
    border-bottom: 1px solid #d9d9d9;
}

.faq .blockfaq {
    color: #343434;
    max-width: 1070px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 0;
}

.faq .titleblock {
    color: #000;
    font-family: HelveticaNeue-Bold;
}

.faq .card,
.faq .card-header {
    border: none;
    background: none;
}

.faq .collapsebody,
.faq .blockbody {
    display: none;
}

.faq .collapsebody.show,
.faq .blockbody.show {
    display: block;
}

.faq .blockbody {
    padding-left: 15px;
}

.faq .card-header {
    padding: 0;
}

.faq .card-header a {
    display: block;
    padding: 13px 0;
}

.faq .blocktitle a {
    display: block;
    padding: 15px 0;
    padding-left: 10px;
}

.home-v1 img {
    max-width: 100%;
}

.home-v1 #v2 {
    background: url('src/assets/hrm/hrm_top_background.jpg') no-repeat center center / 100% 100%;
}

.home-v1 header .col-md-6.pull-left {
    float: left;
}

.linktrial {
    z-index: 9999;
}

.home-v1 header .col-md-6.pull-right {
    float: right;
}

.home-v1 header ul.list-inline a {
    text-transform: none;
}

.home-v1 .header-top {
    position: relative;
}

.home-v1 .header-middle .txt h6,
.home-v1 .header-middle .txt h1 {
    font-size: 40px;
    line-height: 48px;
    position: relative;
    margin-bottom: 50px;
}

.home-v1 .header-middle .txt h6:before,
.home-v1 .header-middle .txt h1:before {
    content: "";
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 80px;
    height: 4px;
    background: #fff;
    border-radius: 2px;
}

.home-v1 header ul.list-inline a.linktrial {
    border: 2px solid #fff;
    padding: 6px 25px;
    border-radius: 5px;
}

.home-v1 header ul.list-inline a.linktrial:hover {
    color: #fff;
}

.home-v1 header ul.list-inline a {
    font-size: 15px;
    font-weight: 600;
}

.home-v1 header ul.list-inline a.active,
.home-v1 header ul.list-inline a:hover,
.home-v1 header ul.list-inline a:focus {
    color: #82c99d;
    outline: none;
}

.home-v1 header ul.list-inline a.downloadhead {
    color: #F8E71C;
}

.home-v1 header ul.list-inline a:before {
    background: #82c99d;
    width: 50%;
}

.home-v1 .header-middle .txt p {
    font-size: 17px;
    color: #f2fffd;
    margin-bottom: 40px;
    font-weight: normal;
}

.home-v1 .header-middle .txt img {
    position: relative;
}

.home-v1 .free,
.home-v1 .view-video {
    height: 45px;
    line-height: 43px;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    padding: 0 35px;
    border: 1px solid #fff;
    border-radius: 25px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-v1 .free img,
.home-v1 .view-video img {
    margin-right: 10px;
}

.home-v1 .view-video:hover,
.home-v1 .view-video:focus {
    color: #fff;
}

.home-v1 .view-help {
    margin-top: 20px;
    position: relative;
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
}

.home-v1 .view-help img {
    /* position: absolute!important; */
    /* left: 0;
  top: 16px; */
    margin-right: 10px;
}

.home-v1 .view-help a {
    line-height: 34px;
    font-size: 14px;
    font-weight: 600;
    display: inline;
    /* text-transform: uppercase; */
    margin-right: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.home-v1 .free {
    margin-right: 10px;
    background: #fff;
    color: #0fa44a;
    border-color: #fff;
    font-family: HelveticaNeue-Medium;
}

.home-v1 .free:hover {
    font-style: italic;
}

.home-v1 footer .copyright {
    background: transparent;
}

.home-v1 footer {
    background: #f7f8fa;
    border-top: 1px solid rgba(0, 0, 0, 0);
    position: relative;
}

.home-v1 .infofooter {
    margin-left: auto;
}

.home-v1 .copyright a {
    margin: 0 10px;
    color: #606771;
}

.home-v1 .copyright a:hover {
    color: #606771;
    text-decoration: underline;
}

.home-v1 .infofooter {
    padding-bottom: 0;
    font-size: 12px;
    color: #606771;
}

.home-v1 .infofooter .d-flex {
    justify-content: flex-end;
}

.home-v1 .infofooter .d-flex span {
    margin: 0 10px;
}

.home-v1 .infofooter a {
    color: #fff;
}

.home-v1 .infofooter a:hover {
    color: #fff;
    text-decoration: underline;
}

.home-v1 .input-field {
    margin-bottom: 30px;
}

.home-v1 .registerfree {
    background: #f7f8fa;
    padding: 100px 0 150px;
}

.home-v1 .form-control {
    height: 45px !important;
    color: #000;
    background: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 25px;
    padding-left: 25px !important;
    font-size: 15px;
}

.home-v1 ::placeholder,
.home-v1 .form-control:placeholder,
.home-v1 ::-webkit-input-placeholder,
.home-v1 .form-control:-webkit-input-placeholder {
    color: #9C9B9F!important;
}

.home-v1 .btn-submit {
    text-transform: uppercase;
    color: #fff;
    background: #0fa44a;
    border-color: #0fa44a;
    margin-top: 0 !important;
}

.home-v1 .btn-submit a {
    color: #fff;
}

.home-v1 .btn-submit a:hover,
.home-v1 .btn-submit a:focus {
    color: #fff;
}

.home-v1 .title-block span {
    display: block;
}

.home-v1 .title-block span:before {
    display: none;
}

.home-v1 .title-block span.label,
.home-v1 .title-block h2.label {
    color: #0fa44a;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.home-v1 .title-block h2.title {
    color: #000;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    margin-bottom: 5px;
}

.home-v1 .title-block span.line {
    height: 4px;
    width: 40px;
    background: #0fa44a;
    margin: 0 auto;
    margin-top: 17px;
    border-radius: 5px;
}

.home-v1 .title-block span.title {
    line-height: 45px;
    font-size: 35px;
    font-weight: bold;
}

.home-v1 .why-use {
    background: #fff;
    padding: 60px 0 100px;
}

.home-v1 .why-use .content-item br {
    display: block;
}

.home-v1 .why-use .title-block {
    margin-bottom: 60px;
}

.home-v1 .title-item {
    font-family: 'HelveticaNeue-Medium';
}

.home-v1 .icon-item {
    margin-bottom: 15px;
}

.home-v1 .content-item {
    color: #76777c;
    padding-right: 15px;
}

.home-v1 .discover .title-block span.line {
    margin-left: 0;
}

.home-v1 .discover .inner {
    border-top: 1px solid #e9eaec;
    padding: 100px 0;
}

.home-v1 .discover .title-block {
    margin-bottom: 30px;
}

.home-v1 .discover .content-block {
    margin-bottom: 25px;
    font-size: 16px;
}

.home-v1 .discover .linkremore a {
    color: #2d2e30;
    font-size: 15px;
    font-family: 'HelveticaNeue-Bold';
}

.home-v1 .discover .linkremore a img {
    margin-left: 15px;
}

.home-v1 #accordion {
    width: 80%;
}

.home-v1 #accordion .card {
    border: none;
    margin-bottom: 10px;
}

.home-v1 #accordion .card-header {
    background: #f7f8fa;
    padding: 0;
    border: none;
}

.home-v1 #accordion .card-header h5 {
    padding: 7px 15px;
    border: 1px solid #e9eaec;
    cursor: pointer;
    position: relative;
    text-transform: uppercase;
    color: #2d2e30;
    font-family: 'HelveticaNeue-Bold';
    font-size: 14px;
    padding-left: 40px;
}

.home-v1 #accordion .card-header h5[aria-expanded="true"]:before {
    background: url('src/assets/images/homev1/add-box.png') no-repeat top left;
    background-size: 100% 100%;
}

.home-v1 #accordion .card-header h5:before {
    content: "";
    position: absolute;
    height: 24px;
    width: 24px;
    left: 8px;
    top: 7px;
    background: url('src/assets/images/homev1/add-box-plus.png') no-repeat top left;
    background-size: 100% 100%;
    transition: all 0.2s ease;
}

.home-v1 #accordion .card-body {
    padding: 1rem;
    font-size: 13px;
    color: #2d2e30;
}

.home-v1 #accordion .card-body a {
    color: #2d2e30;
}

.home-v1 #accordion .iconarrow {
    margin-top: -5px;
}

.home-v1 #accordion .bx-accordion {
    padding-right: 30px;
}

.home-v1 #accordion .bx-accordion ol {
    margin: .25rem 0 .75rem;
    list-style: decimal;
}

.home-v1 #accordion .bx-accordion ol li {
    margin: .25rem 0 .25rem 1.25rem;
}

.home-v1 #accordion .bx-accordion b,
.home-v1 #accordion .bx-accordion strong {
    font-weight: bold;
}

.home-v1 #accordion .bx-accordion i {
    font-style: italic;
}

.home-v1 #accordion .bx-accordion p {
    margin-bottom: 10px;
}

.home-v1 #accordion .bx-accordion h5 {
    font-weight: bold;
}

.home-v1 #accordion .bx-accordion a {
    color: #0FA44A;
    font-weight: bold;
}

.home-v1 .feature {
    position: relative;
    padding: 80px 0 0;
    overflow: hidden;
}

.home-v1 .feature .bgsection {
    background: url('src/assets/hrm/hrm_feature_background.png') no-repeat top left;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 1289px;
}

.owl-carousel .owl-dots {
    display: flex !important;
    justify-content: center;
}

.home-v1 .feature .inner {
    position: relative;
}

.home-v1 .feature .title-block {
    color: #fff;
    margin-bottom: 25px;
}

.home-v1 .feature .title-block span.label {
    color: #82c99d;
}

.home-v1 .feature .title-block span.line {
    background: #fff;
    margin-left: 0;
}

.home-v1 .feature .content-block {
    color: #fffcff;
    font-size: 16px;
    margin-bottom: 30px;
}

.home-v1 .feature .group-download a+a {
    margin-left: 15px;
}

.home-v1 .feature .infosection {
    padding-top: 16px;
}

.home-v1 .feature .slideinfo {
    color: #fffcff;
    padding-top: 215px;
    color: #fff;
}

.home-v1 .feature .slideinfo h5 {
    font-size: 17px;
    font-family: 'HelveticaNeue-Medium';
    margin-bottom: 12px;
}

.home-v1 .feature .featurehot-v2>div {
    font-size: 15px;
    opacity: 0.9;
    margin-bottom: 3px;
    cursor: pointer;
    color: #fff;
    padding: 16px;
    min-height: 74px;
}

.home-v1 .feature .featurehot-v2>div.active {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.1);
    min-height: 74px;
}

.home-v1 .feature .featurehot-v2>div.active .iconarrow {
    opacity: 1;
}

.home-v1 .feature .featurehot-v2 .iconarrow {
    margin-right: 10px;
    opacity: 0;
}

.home-v1 .feature .featurehot-v2-2>div {
    font-size: 15px;
    opacity: 0.9;
    margin-bottom: 3px;
    cursor: pointer;
    color: #fff;
    padding: 16px;
    min-height: 74px;
}

.home-v1 .feature .featurehot-v2-2>div.active {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.1);
    min-height: 74px;
}

.home-v1 .feature .featurehot-v2-2>div.active .iconarrow {
    opacity: 1;
}

.home-v1 .feature .featurehot-v2-2 .iconarrow {
    margin-right: 10px;
    opacity: 0;
}

.home-v1 .feature .slidedemo .images {
    position: relative;
}

.home-v1 .feature .innerslide {
    position: absolute;
    top: 32px;
    left: 60px;
    width: 260px;
    border-radius: 40px;
    overflow: hidden;
}

.home-v1 .feature .innerslide .owl-dots {
    opacity: 0;
}

.home-v1 .feature .innerslide .owl-prev,
.home-v1 .feature .innerslide .owl-next {
    background: url('src/assets/images/homev1/slide-prev-disable.png') no-repeat top left;
    height: 40px;
    width: 40px;
    padding: 0;
    background-size: 100%;
    text-indent: 9999px;
    margin: 0;
    display: none;
}

.home-v1 .feature .innerslide .owl-prev:hover,
.home-v1 .feature .innerslide .owl-next:hover {
    background: url('src/assets/images/homev1/slide-prev.png') no-repeat top left;
    background-size: 100%;
}

.home-v1 .feature .innerslide .owl-prev {
    left: 123%;
    top: 15%;
    margin-right: 15px;
}

.home-v1 .feature .innerslide .owl-next {
    left: 144%;
    right: inherit;
    top: 15%;
}

.home-v1 .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes scale {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.7);
    }
}

@keyframes scale {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.7);
    }
}

.home-v1 .scale {
    -webkit-animation-name: scale;
    animation-name: scale;
    animation: scale 1s linear 1s infinite alternate;
}

.modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
}

.modal-dialog .close {
    position: absolute;
    right: -30px;
    opacity: 1;
    top: -20px;
    width: 50px;
    z-index: 9;
}

.modal-body {
    padding: 5px;
}

@media (max-width: 1200px) {
    .home-v1 header ul.list-inline a {
        font-size: 14px;
        font-weight: 600;
    }
    .home-v1 header ul.list-inline a:before {
        display: none;
    }
    .home-v1 header ul.list-inline a.downloadhead {
        color: #333;
    }
    .home-v1 .header-middle .txt {
        padding-top: 30px;
        padding-right: 0px;
    }
    .home-v1 .header-middle .txt img {
        position: relative;
        width: 19px;
    }
    .home-v1 .why-use .content-item br {
        display: none;
    }
    .home-v1 .feature .innerslide {
        position: absolute;
        top: 40px;
        left: 55px;
        width: 210px;
    }
    .home-v1 .copyright {
        margin-left: 10px;
    }
}

@media (max-width: 992px) {
    .home-v1 header .icon-menu {
        margin-top: 16px;
    }
    .home-v1 .header-middle .txt h6,
    .home-v1 .header-middle .txt h1 {
        font-size: 35px;
        line-height: 40px;
    }
    .home-v1 .grouplink {
        flex-direction: column;
        padding-right: 85px;
    }
    .home-v1 .grouplink a {
        margin-bottom: 15px;
        margin-right: 0;
    }
    .home-v1 .feature .innerslide {
        position: absolute;
        top: 40px;
        left: 45px;
        width: 150px;
    }
    .home-v1 .feature .infosection {
        padding-top: 35px;
    }
    .home-v1 .feature .slideinfo {
        padding-top: 115px;
    }
    .home-v1 .feature .innerslide .owl-prev {
        top: 6%;
    }
    .home-v1 .feature .innerslide .owl-next {
        left: 160%;
        right: inherit;
        top: 6%;
    }
    .home-v1 .feature .bgsection {
        height: 100%;
    }
    .home-v1 .feature {
        padding: 30px 0 0;
    }
    .home-v1 .innerfooter {
        display: flex;
        align-items: flex-end;
        padding: 35px 0 25px;
    }
}

@media (max-width: 767px) {
    .modal-dialog .close {
        display: none;
    }
    .modal-dialog {
        max-width: 400px;
        margin: 1.75rem auto;
    }
    .home-v1 header {
        /* min-height: 700px; */
        background-position: center right;
        background-size: cover;
        background-image: none;
        background-color: #0fa44a;
    }
    .home-v1 header .col-md-6.pull-left,
    .home-v1 header .col-md-6.pull-right {
        float: none;
    }
    .home-v1 header .col-md-6.pull-right {
        display: none;
    }
    .home-v1 .header-middle {
        padding-top: 0;
        padding-bottom: 60px;
        margin-top: 10px;
    }
    .home-v1 .header-middle .txt {
        padding-top: 0;
    }
    .home-v1 .header-middle .txt h6,
    .home-v1 .header-middle .txt h1 {
        font-size: 26px;
        line-height: 32px;
        margin-bottom: 40px;
    }
    .home-v1 .header-middle .txt h6:before,
    .home-v1 .header-middle .txt h1:before {
        left: 50%;
        transform: translateX(-50%);
        bottom: -20px;
    }
    .home-v1 .header-middle .txt img {
        display: none;
    }
    .home-v1 .img-right {
        margin-top: 30px;
    }
    .home-v1 .grouplink {
        padding: 0;
        width: 80%;
        margin: 0 auto;
    }
    .home-v1 .title-block span.title {
        font-size: 30px;
        line-height: 36px;
    }
    .home-v1 .content-block .item {
        text-align: center;
    }
    .home-v1 .why-use {
        padding: 50px 0 30px;
    }
    .home-v1 .linkremore {
        margin-bottom: 30px;
    }
    .home-v1 .discover .inner {
        padding: 30px 0;
    }
    .home-v1 .feature .infosection {
        padding-top: 15px;
    }
    .home-v1 .group-download {
        width: 70%;
        margin: 0 auto;
    }
    .home-v1 .feature .group-download a+a {
        margin-left: 0;
        margin-top: 15px;
    }
    .home-v1 .feature .group-download a {
        display: block;
    }
    .home-v1 .feature .innerslide {
        position: absolute;
        top: 30px;
        left: 35px;
        width: 110px;
    }
    .home-v1 .feature {
        padding: 90px 0;
    }
    .home-v1 .feature .title-block span.line {
        margin: 0 auto;
        margin-top: 17px;
    }
    .home-v1 .pr-0,
    .home-v1 .pl-0 {
        padding: 0 15px !important;
    }
    .home-v1 .feature .content-block {
        text-align: center;
    }
    .home-v1 .use {
        padding: 25px 0 30px;
        margin-top: 25px;
        border-top: 1px solid #e9eaec;
    }
    .home-v1 .registerfree {
        padding: 30px 0 30px;
    }
    .home-v1 .innerfooter {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .home-v1 .infofooter {
        margin-left: 0;
    }
    .home-v1 .copyright {
        margin-left: 0px;
        padding-left: 0;
        border: none;
    }
    .home-v1 .copyright {
        padding: 0;
        margin: 7px 0;
    }
    .home-v1 .copyright a {
        margin: 0;
        margin-right: 3px;
    }
    .home-v1 .infofooter .d-flex span {
        display: none;
    }
    .home-v1 .infofooter .d-flex {
        display: flex!important;
        justify-content: space-between;
    }
    .home-v1 footer {
        background-size: cover;
    }
    .home-v1 .feature .innerslide .owl-prev {
        left: 137%;
    }
    .home-v1 .feature .innerslide .owl-next {
        left: 182%;
    }
}

@media (max-width: 576px) {
    .home-v1 #v2 {
        background: #0EA44A;
    }
    .home-v1 .feature {
        padding: 30px 0 50px;
    }
    .home-v1 .feature .title-block {
        text-align: center !important;
    }
    .home-v1 .feature .innerslide .owl-prev,
    .home-v1 .feature .innerslide .owl-next {
        display: none !important;
    }
    .home-v1 #accordion {
        width: 100%;
    }
    .home-v1 .feature .slideinfo {
        padding-top: 0;
        color: #333;
        margin-top: -30px;
        text-align: center;
    }
    .home-v1 .feature .innerslide {
        position: absolute;
        top: 50px;
        left: 65px;
        width: 245px;
    }
    .home-v1 .feature .bgsection {
        height: 100%;
        background-size: cover;
        background-position: center center;
    }
}

@media (max-width: 576px) {
    .home-v1 .feature .innerslide {
        width: 275px;
        left: 70px;
    }
}

@media (max-width: 400px) {
    .home-v1 .feature .innerslide {
        width: 243px;
        left: 65px;
    }
}

@media (max-width: 360px) {
    .home-v1 .feature .innerslide {
        width: 225px;
        left: 65px;
    }
}

@media (max-width: 320px) {
    .home-v1 .feature .innerslide {
        width: 190px;
        left: 65px;
    }
}

@media (max-width: 1200px) {
    header .header-middle img,
    .work .row img {
        max-width: 100%;
    }
}

@media (max-width: 1024px) {
    .header-middle .txt img {
        width: 80%;
    }
}

@media (max-width: 992px) {
    section.work .title-item {
        width: inherit;
        padding: 0 15px;
        min-width: 150px;
    }
    section.work .text-right,
    section.work .text-left {
        padding: 0;
    }
    section.work .content-block .item .title-item:before,
    section.work .content-block .item .title-item:after {
        display: none;
    }
    section.work .line-bottom:before,
    section.work .line-top:before {
        display: none;
    }
    section.video {
        min-height: 450px;
    }
    section.video iframe {
        height: 350px;
    }
    section.contact .content-block {
        width: 100%;
    }
    .header-top {
        z-index: 9;
    }
    header .icon-menu {
        display: block;
        margin-top: 15px;
    }
    nav ul.list-inline.active {
        transform: scale(1.1);
    }
    nav ul.list-inline {
        position: absolute;
        right: 0;
        top: 100%;
        width: 200px;
        background: #fff;
        border-radius: 10px;
        transition: all 0.35s ease;
        transform: scale(0);
        transform-origin: top right;
    }
    nav ul.list-inline li.list-inline-item {
        width: 100%;
        margin: 0;
    }
    nav ul.list-inline li.list-inline-item a {
        display: block;
        padding: 10px 20px;
        font-weight: normal;
        font-size: 11px;
        color: #333;
        /* text-align: center; */
        border-bottom: 1px solid #ccc;
    }
    nav ul.list-inline li.list-inline-item:last-child a {
        border: none;
    }
    .work .row-1 .title-item {
        margin-top: 70px;
    }
    br {
        display: none;
    }
    .shop section.price .table thead th.last {
        display: none;
    }
    .shop section.price .content-block,
    .shop section.price .block-bonus {
        width: 100%;
    }
    .shop section.price .table thead th.col1 {
        padding: 25px 30px 50px;
    }
    .shop section.price tbody td.col1 {
        padding-left: 30px;
        text-align: left;
    }
    .shop section.price .table thead th {
        padding: 40px 10px 35px;
    }
    .shop section.price .block-bonus tbody td,
    .shop section.price .block-bonus thead th {
        text-align: center;
        padding-left: 0 !important;
    }
    .shop .info .title-block {
        font-size: 25px;
        line-height: 25px;
    }
}

@media (max-width: 767px) {
    .shop .info .pd-left {
        padding-left: 15px;
        text-align: center;
    }
    .shop .info .pd-right {
        padding-right: 15px;
    }
    .shop .info .pd {
        padding: 35px 0;
    }
    .copyright br {
        display: block;
    }
    .shop .info .title-block {
        margin-top: 15px;
    }
    .shop .info img {
        width: 60%;
    }
    .shop .info .col-sm-5 {
        text-align: center;
    }
    .shop .content-block {
        text-align: center;
    }
    .shop section.price .table thead th.col1 {
        padding: 40px 10px 35px;
    }
    .shop section.price .font2 {
        font-size: 13px;
    }
    footer .copyright {
        padding: 40px 25px;
    }
    .work .row-1 .img:before,
    .work .row-1 .img:after,
    .work .row-2 .img:before {
        display: none;
    }
    .grid-3 {
        grid-template-columns: repeat(3, 1fr) !important;
        grid-gap: 80px 30px !important;
    }
    .d-flex {
        display: block !important;
    }
    header .icon-menu img {
        width: 20px;
    }
    .header-middle .txt {
        padding-top: 0;
    }
    .header-middle {
        margin-top: 70px;
        text-align: center;
    }
    .header-middle br {
        display: none;
    }
    .header-middle .txt h6,
    .header-middle .txt h1 {
        font-size: 25px;
        line-height: 25px;
    }
    header {
        min-height: 500px;
    }
    .header-middle .txt p {
        font-size: 15px;
    }
    .header-middle .img-right {
        width: 180px;
        transform: translateX(-50%);
        left: 50%;
    }
    header .icon-menu {
        margin-top: 25px;
        margin-right: 10px;
    }
    .download-git {
        margin-top: 220px;
        text-align: center;
        padding: 40px 0 20px;
    }
    .download-git .d-flex .btn {
        width: 100%;
        margin-bottom: 25px;
        margin-left: 0 !important;
    }
    .why-use {
        padding: 50px 0;
    }
    .title-block {
        font-size: 25px;
        line-height: 25px;
    }
    .title-block p {
        font-size: 16px;
    }
    .title-block p br {
        display: none;
    }
    .content-item br {
        display: none;
    }
    .buy-now {
        padding: 50px 0 25px;
    }
    .buy-now h6 {
        font-size: 25px;
        line-height: 25px;
    }
    .buy-now h6 br {
        display: none;
    }
    .buy-now .hurry {
        font-size: 17px;
        color: #7efc95;
    }
    .buy-now .btn {
        width: 100%;
        height: 45px;
        line-height: 43px;
        margin-bottom: 25px;
        margin-left: 0!important;
    }
    .work {
        padding: 50px 0;
    }
    .work .content-block * {
        text-align: center !important;
    }
    .work .row-1 {
        position: relative;
        margin-bottom: 50px;
    }
    .work .row-1 .col-img {
        position: absolute;
        top: 0;
    }
    .work .row-1 .title-item {
        padding-top: 170px;
    }
    .work .row-2 .img {
        margin-right: 0;
    }
    .work .row-2 .title-item {
        margin-top: 30px;
    }
    .work .row-2 {
        margin-bottom: 50px;
    }
    footer .footer-middle a img {
        max-width: 100%;
    }
}

@media (min-width: 568px) and (max-width: 766px) {
    .work .row-1 .title-item {
        padding-top: 270px;
    }
}

@media (max-width: 567px) {
    section.work {
        padding: 70px 0 30px;
    }
    section.work .row-img {
        margin: 60px 0 40px;
    }
    section.work ul li {
        padding: 0 !important;
    }
    section.work ul li:before {
        display: none !important;
    }
    footer .footer-middle {
        padding: 0px 0 20px;
    }
    footer .footer-middle .col-bg {
        background: #f5f5f5;
        text-align: center;
        padding: 25px 0;
    }
    footer .footer-middle .logo-footer {
        margin-top: 25px;
        padding: 20px 0;
        text-align: center;
    }
    footer .footer-middle .list-footer-dl {
        margin: 0;
        padding-left: 25%;
        padding-top: 25px;
    }
    footer .footer-middle p.pr-4 {
        text-align: center;
        padding: 0;
        padding-bottom: 30px;
    }
    section.video iframe {
        height: 250px;
    }
    section.video {
        min-height: 400px;
    }
}

@media (max-width: 400px) {
    .work .row-1 .title-item {
        padding-top: 150px;
    }
}

@media (max-width: 320px) {
    .work .row-1 .title-item {
        padding-top: 100px;
    }
}


/***********home v-2***********/

@media (min-width: 1200px) {
    .home-v1 header .itemSlider {
        min-height: max-content;
        margin-top: 60px;
    }
}

.itemSlider .img-right-feature {
    margin-top: 96px;
}

.home-v1 .partner .item {
    box-shadow: 0 2px 10px rgba(133, 144, 166, .15), inset 0 0 0 1px rgba(0, 0, 0, .08);
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, .08);
}

.home-v1 .partner .title-block {
    margin-bottom: 40px;
}

.home-v1 .partner {
    padding: 60px 0px;
}

.home-v1 header .owl-prev {
    float: left;
    transform: rotate(180deg);
}

.home-v1 header .owl-next {
    float: right;
}

.home-v1 header .owl-theme .owl-nav [class*=owl-] {
    margin: 0 30px;
}

.home-v1 header .owl-next,
.home-v1 header .owl-prev {
    background: url('src/assets/images/homev1/arrowSlider.png') no-repeat top left !important;
    height: 40px !important;
    width: 40px !important;
    background-size: 100% !important;
    text-indent: -9999px;
    top: 50%;
    width: 100%;
    transform: translateY(-50%) translateX(-50%);
    opacity: 1;
    transition: all 0.1s ease;
    position: absolute;
}

.home-v1 header .owl-prev {
    transform: translateY(-50%) translateX(-50%) rotateY(180deg);
}

.home-v1 header .owl-next {
    right: -45px;
}

.home-v1 header .owl-dots {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
    transition: all 0.1s ease;
}

.home-v1 .owl-theme .owl-dots .owl-dot span {
    background: #ccc;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin: 0 5px;
    border-radius: 100%;
}

.home-v1 .owl-theme .owl-dots .owl-dot.active span,
.home-v1 .owl-theme .owl-dots .owl-dot:hover span {
    background: #0FA44A;
}

.home-v1 .owl-dot {
    display: inline-block;
}

.home-v1 .img-right {
    position: absolute;
    right: 0;
    top: 60px;
    width: 50%;
}

.btnHeader {
    position: relative;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
}

.btnHeader .btnHeader_Line {
    background: #0FA44A;
    border: 1px #fff solid;
    padding: 5px 30px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #fff!important;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    min-height: 40px;
    justify-content: center;
    align-items: center;
}

.btnHeader .btnHeader_Fill {
    margin-right: 10px;
    background: #fff;
    border: 1px #fff solid;
    padding: 5px 30px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: #0FA44A!important;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    min-height: 40px;
    justify-content: center;
    align-items: center;
}

.home-v1 .input-trial {
    border-radius: 8px !important;
}

.home-v1 .input-trial .btn.btn-submit {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 0;
    text-align: center;
    width: 40%;
    line-height: inherit;
    height: calc(100% - 10px) !important;
    border-radius: 7px;
    font-weight: bold;
}

.home-v1 .input-trial .btn.btn-submit span {
    font-weight: bold;
}

.home-v1 .input-trial .form-control {
    border-radius: 7px !important;
    font-size: 15px;
}

.home-v1 .input-trial .input-field {
    margin-bottom: 0;
}

.home-v1 .feature .infosectionright {
    padding-top: 140px;
}

.home-v1 .feature .infosectionright .title-block {
    margin-bottom: 15px;
}

.home-v1 .feature .infosectionright .content-block {
    opacity: 0.9;
    margin-bottom: 25px;
    font-size: 16px;
}

.home-v1 .feature .infosectionright .content-block span {
    font-size: 15px;
}

.home-v1 .feature .infosectionright .content-block b {
    font-weight: bold;
    opacity: 1;
    font-size: 17px;
}

.home-v1 .noti-trial {
    padding: 80px 0 50px;
}

.home-v1 .noti-trial .inner {
    display: flex;
    padding: 50px;
    text-align: left;
    justify-content: center;
    align-items: center;
    background: #0FA44A;
    color: #fff;
    border-radius: 10px;
}

.home-v1 .noti-trial .inner .linktrial {
    margin-left: auto;
    background: #fff;
    padding: 10px 35px;
    border-radius: 6px;
    font-weight: BOLD;
    font-size: 16px;
    color: #0FA44A;
    text-transform: uppercase;
    min-width: 220px;
    text-align: center;
}

.home-v1 .noti-trial .inner h5 {
    font-size: 33px;
    color: #fff;
    margin: 0;
}

.home-v1 .noti-trial .inner p {
    font-size: 20px;
    opacity: 0.5;
}

.home-v1 .infomation-contact {
    padding: 35px 0;
}

@media (max-width: 700px) {
    .home-v1 .box {
        padding: 15px 15px;
        box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.16);
        border-radius: 5px;
    }
    .home-v1 .box .icon {
        margin-right: 15px;
    }
    .home-v1 .box .icon img {
        /* display: block; */
        margin-left: 15px;
        justify-content: center;
    }
}

.home-v1 .box {
    padding: 15px 15px;
    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.16);
    border-radius: 5px;
}

.home-v1 .box .icon {
    margin-right: 15px;
}

.home-v1 .box .icon img {
    /* display: block; */
    /* margin-left: 15px; */
    justify-content: center;
}

.home-v1 .box h6 {
    color: #2d2e30;
    font-size: 15px;
    font-weight: 600;
    margin: 0 0 2px 0;
    line-height: initial;
}

.home-v1 .box .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-v1 .box p {
    font-size: 13px;
    margin: 0;
    line-height: initial;
}

.home-v1 .location-contact {
    padding: 30px 0 70px;
}

.home-v1 .location-info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
}

.home-v1 .location-info-left {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.home-v1 .location-info p {
    text-align: center;
}

.home-v1 .location-info h6 {
    color: #2d2e30;
    font-size: 15px;
    margin-bottom: 7px;
}

.home-v1 .location-info a.cl1 {
    margin-top: 25px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 10px;
}

.home-v1 footer .copyright {
    text-align: center;
}

.home-v1 .linkfooter {
    justify-content: center;
}

.home-v1 .linkfooter span {
    color: #606771;
}

.home-v1 .copyright a {
    font-size: 13px;
}

.home-v1 footer p {
    color: #606771;
    font-size: 13px;
}

.home-v1 footer .copyright {
    padding: 15px 0;
}

.btn.disable {
    filter: brightness(85%);
}

@media (max-width: 1024px) {
    .home-v1 .feature .infosection {
        padding-top: 50px;
    }
    .home-v1 .feature .infosectionright {
        padding-top: 105px;
    }
    .home-v1 .pl-5,
    .home-v1 .px-5 {
        padding-left: 0 !important;
    }
}

@media (max-width: 992px) {
    /* .home-v1 .input-trial .btn.btn-submit{
        background: #fff;
        width: 100%;
      right: 0;
      height: 40px !important;
     position: relative;
     color: #0FA44A;
  } */
    .home-v1 header {
        min-height: 655px;
    }
    .home-v1 .header-middle .txt p {
        line-height: 22px;
        font-size: 16px;
    }
    .home-v1 .view-help {
        text-align: center;
    }
    .home-v1 .header-middle .txt img {
        position: relative !important;
        width: 19px;
        top: 4px;
    }
    .home-v1 .noti-trial .inner {
        padding: 50px 15px;
    }
    .home-v1 .innerfooter {
        align-items: center;
        justify-content: center;
        padding: 0;
    }
    .home-v1 .location-info-left {
        border: none;
    }
    nav ul.list-inline li.list-inline-item:last-child a {
        border: none;
        padding: 10px 20px;
    }
}

@media (max-width: 767px) {
    .home-v1 .img-right {
        position: relative;
        width: 100%;
        top: 0;
        margin-top: 50px;
    }
    .bottom-feature {
        padding-bottom: 0px;
    }
    .home-v1 .header-middle {
        margin-top: 30px;
    }
    .home-v1 .pr-0,
    .home-v1 .pl-0 {
        padding: 0 15px !important;
    }
    .home-v1 .feature .infosectionright {
        background: #0FA44A;
        padding-top: 50px;
        padding-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
        border-radius: 7px;
    }
    .home-v1 .group-download {
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
    .home-v1 .noti-trial .inner {
        flex-direction: column;
    }
    .home-v1 .noti-trial .innertxt {
        text-align: center;
    }
    .home-v1 .noti-trial .inner h5 {
        font-size: 25px;
        color: '#fff';
    }
    .home-v1 .noti-trial .inner p {
        font-size: 15px;
        opacity: 0.5;
    }
    .home-v1 .noti-trial .inner .linktrial {
        margin-left: 0;
        margin-top: 15px;
    }
    .home-v1 .box {
        text-align: center;
        margin-bottom: 20px;
        padding: 15px 15px;
    }
    .home-v1 .infomation-contact {
        padding: 35px 0;
    }
    .home-v1 .location-contact {
        padding: 0px 0 30px;
    }
    .home-v1 .location-info {
        text-align: center;
        border-bottom: 1px solid #f1f1f1;
    }
    .home-v1 .input-trial .form-control {
        text-align: left;
        padding-left: 15px!important;
    }
    .home-v1 .input-trial .btn.btn-submit span {
        font-weight: bold;
        font-size: 15px;
    }
    .home-v1 .noti-trial .inner {
        border-radius: 7px;
    }
}

@media (max-width: 500px) {
    .grid-3 {
        display: grid;
        grid-template-columns: repeat(1, 1fr)!important;
        grid-gap: 50px 20px;
    }
    .home-v1 header {
        min-height: 430px;
    }
    .home-v1 .view-help {
        display: none;
    }
    .home-v1 .header-middle .txt h6,
    .home-v1 .header-middle .txt h1 {
        text-align: left
    }
    .home-v1 .header-middle .txt h6:before,
    .home-v1 .header-middle .txt h1:before {
        left: 0;
        transform: translateX(0);
    }
    .home-v1 .header-middle .txt p {
        text-align: left;
        margin-bottom: 20px;
    }
    .home-v1 .grid-3 {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 50px 20px;
    }
    .home-v1 .content-item {
        font-size: 13px;
    }
    .home-v1 .noti-trial {
        padding: 0
    }
    .btnHeader {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-right: 0px;
    }
    .btnHeader .btnHeader_Fill {
        flex: 1;
        width: 100%;
        display: flex;
        justify-content: right;
        min-height: 40px;
        margin: 5px 0;
    }
    .btnHeader .btnHeader_Line {
        flex: 1;
        width: 100%;
        display: flex;
        justify-content: right;
        min-height: 40px;
        margin: 5px 0;
    }
    .header-middle .btn {
        padding-left: 8px;
        padding-right: 8px;
    }
}

.modal-video {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9000;
    text-align: center;
}

.modal-video-inner {
    /* width: 80%; */
    max-width: 85%;
    height: 100%;
    width: 85%;
    padding-bottom: 47.8125%;
    height: 0;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)!important;
    /* overflow: hidden; */
}

.modal-video-inner .iframe-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.help-use * {
    color: #000;
    font-size: 15px;
}

.help-use h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.help-use h2 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}

.help-use ol {
    list-style: decimal;
    padding-left: 20px;
    list-style-position: outside;
    margin: 20px 0 40px 20px;
    display: block;
}

.help-use ul {
    list-style: disc;
    padding-left: 20px;
    list-style-position: outside;
    margin: 20px 0 40px 20px;
    display: block;
}

.help-use b,
.help-use strong {
    color: #000;
    font-weight: bold;
}

.help-use p {
    margin: 10px 0 10px 0;
    text-align: left;
}

.help-use img {
    display: block;
    margin: 10px 0;
    max-width: 320px;
    width: 100%;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    border-radius: 5px;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.1);
}

.help-use .help-use-img img {
    display: block;
    margin: 10px 0 30px 0;
    max-width: 320px;
    width: 100%;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    border-radius: 5px;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.1);
}

.help-use .help-use-2img img {
    display: block;
    margin: 10px 0 30px 0;
    max-width: 360px;
    width: 100%;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    border-radius: 5px;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.1);
}

.help-use .help-use-3img img {
    display: block;
    margin: 10px 0 30px 0;
    max-width: 480px;
    width: 100%;
    border: 1px rgba(0, 0, 0, 0.1) solid;
    border-radius: 5px;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.1);
}

.partner {
    overflow: hidden;
}

.partner .owl-prev,
.partner .owl-next {
    background: url('../../assets/images//homev1/arrowSlider.png') no-repeat top left !important;
    height: 40px;
    width: 40px;
    padding: 0;
    background-size: 100%;
    text-indent: 9999px;
    margin: 0;
    filter: brightness(100%);
    background-size: 100% 100% !important;
    -webkit-filter: brightness(100%);
}

.partner .owl-prev:hover,
.partner .owl-next:hover {
    filter: brightness(90%);
}

.partner .owl-prev {
    transform: rotate(180deg);
    top: 35%;
    left: -55px;
}

.partner .owl-next {
    transform: rotate(0deg);
    top: 35%;
    right: -55px;
}

@media (max-width: 1024px) {
    .partner .owl-prev,
    .partner .owl-next {
        display: none;
    }
    .header-middle .btn {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.grey-box-container .grey-box {
    background-color: #F5F5F5;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 20px 15px 20px 15px;
    border-radius: 6px;
    height: 100%;
}

.grey-box-container .title-screen {
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
    color: #0d3c77;
}

.grey-box-container .h4 {
    font-size: 1.1rem;
}

.grey-box-container .inside-box {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}