.contactpage {
    position: relative;
  }
  .contactpage header {
    background: url(src/assets/images/homev1/bg-feature.png) no-repeat top left;
    background-size: 100% 100%;
  }
  .contactpage.home-v1 .infomation-contact {
    /* margin-top: -135px; */
    position: relative;
  }
  .contactpage.home-v1 .box {
    background: #fff;
  }
  @media (max-width: 992px) {
    .contactpage.home-v1 .infomation-contact {
      margin-top: 0;
    }
    .contactpage header {
      background-size: cover;
    }
  }
  @media (max-width:500px) {
    .contactpage header {
      background-image: none;
      background-color: #0FA44A;
    }
  }