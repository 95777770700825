html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  transition: all 0.25s ease;
}
a:hover {
  text-decoration: none;
}


.bgrepeatTop-demo {
  top: 0;
  height: 69px;
  background-color: #0ea44a;
  position: absolute;
  width: 100%;

  border-bottom: 3px solid #094c21;
}


.wrapContent-demo {
  height: 100vh;
  width: 100%;
  background-size: 100%;
  background-color: #eacd95;
}
.innerCover-demo {
  width: 80%;
  margin: 0 auto;
  position: relative;
  /* background-color: #0ea44a; */
 
}
.innerCover-demo img {
  max-width: 100%;
  display: block;
}
.innerCover-demo,
.bgCover-demo {
  display: block;
}
.bgCover-demo {
  position: relative;
  z-index: 9;
  background: url(./assets/images/bgcenter.png) top center repeat-x;
  background-size: 100% 100%;
}
.bgCover-demo:before,
.bgCover-demo:after {
  content: "";
  position: absolute;
  top: 0;
  left: -890px;
  height: 100%;
  width: 900px;
  background: url(./assets/images/bgcenter.png) top center repeat-x;
  z-index: 999;
  display: block;
  background-size: 100% 100%;
}
.bgCover-demo:after {
  /* background: url(./images/bgright.png) top -3px left repeat-x; */
  left: inherit;
  background-size: 100% 100%;
  right: -890px;
}
.bgCover-demo.hide {
  opacity: 0;
  transition: 0.2s ease;
}
.btn-demo {
  margin-left: auto;
}
.btn-demo a {
  display: block;
  height: 100%;
  background: #fff;
  padding: 3px 30px;
  color: #0ea44a;
  font-weight: bold;
  font-size: 15px;
  border-radius: 7px;
  margin-right: 30px;
  transition: all 0.3s ease;
}

.logo-demo {
  vertical-align: middle;
  display: flex;
  font-size: 25px;
  align-items: center;
  padding-left: 20px;
}
.logo-demo img {
  margin-right: 10px;
}

.logo-demo span {
    display: inline-block;
    font-size: 17px;
    font-weight: bold;
    margin-left: 20px;
    margin-top: 0px;
    vertical-align: middle;
    border-left: 2px solid #fff;
    padding-left: 20px;
}
.top-demo {

  display: flex;
  align-items: center;
  color: #fff;
  padding: 15px 0 !important;
  min-height: 80px;
}
.groupdl-demo {
  position: absolute;
  bottom: 30px;
  right: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.groupdl-demo a {
  display: inline-block;
  transition: all 0.4s ease;
}
.groupdl-demo a:hover {
  transform: translateY(-7px);
  filter: brightness(110%);
}
.groupdl-demo a + a {
  margin-left: 10px;
}
.noti-demo {
  position: absolute;
  right: 14%;
  bottom: 140px;
  width: 320px;
  transform: scale(0);
  transform-origin: top right;
  transition: all 0.3s ease;
  z-index: 10;
  opacity: 0;
}
.noti-demo.hidden {
  opacity: 0;
}
.noti-demo.show {
  opacity: 1;
  transform: scale(1);
}
.noti2-demo {
  position: absolute;
  right: 30%;
  bottom: 62%;
  width: 25%;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 10;
  transform-origin: bottom right;
}
.noti2-demo.hidden {
  opacity: 0;
}
.noti2-demo.show {
  opacity: 1;
  transform-origin: bottom right;
  transition: all 0.5s ease;
}
.d-demo,
.d1-demo {
  position: absolute;
  bottom: 16.5%;
  width: 30%;
  z-index: 10;
  left: 15%;
  transition: all 0.4s ease;
}
.d-demo.d1-demo,
.d1-demo.d1-demo {
  opacity: 0;
}
.d-demo.show,
.d1-demo.show {
  opacity: 1;
}
.d-demo.hidden,
.d1-demo.hidden {
  opacity: 0;
}
@media (max-width: 1400px) {
  .d-demo,
  .d1-demo {
    bottom: 16%;
  }
}
.hand-demo {
  position: absolute;
  right: -260px;
  z-index: 10;
  width: 18%;
  bottom: -110px;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;
}
.hand-demo.show {
  right: -48px;
  z-index: 11;
  width: 18%;
  bottom: -30px;
  opacity: 1;
  visibility: visible;
}
.group-down {
  display: block;
  padding: 10px 0;
}
.bgCover-demo img {
  width: 100%;
}
.modal-demo {
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 99;
  /* Sit on top */
  padding-top: 30px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.7);
  /* Black w/ opacity */
}
/* Modal Content */
.modal-content-demo {
  background-color: #fefefe;
  margin:20px auto;
  padding: 40px;
  max-width: 890px!important;
  border-radius: 7px;
  width: 100%;
  max-width: 80%;
  position: relative;
  font-size: 18px;
  color: #000;
  line-height: 25px;
}
.modal-content-demo.modal-sm {
  max-width: 640px;
}
.modal-content-demo.modal-lg {
  max-width: 1200px;
}
.modal-content-demo.modal-full {
  max-width: 100%;
}
.modal-content-demo h4 {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 30px;
  color: #000;
  text-align: center;
}
.modal-content-demo h6 {
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 10px 0;
  color: #000;
  text-transform: uppercase;
}
.modal-content-demo .inner {
  font-size: 15px;
  line-height: 24px;
}
.modal-content-demo .inner b {
  font-weight: bold;
}
.modal-content-demo ol,
.modal-content-demo ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.modal-content-demo ul li {
  margin-bottom: 10px;
}
.modal-content-demo .inner-bottom {
  padding: 40px 0 0;
  text-align: right;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
} 

.modal-content-demo .inner-bottom a {
  font-weight: bold;
  margin: 0 10px;
  color: #83909E;
  padding: 6px 20px;;
  border-radius: 5px;
  border: 1px #ddd solid;
  min-width: 120px;
  text-align: center;
}
.modal-content-demo .inner-bottom.btn-submit a{
  border: 0 #ddd solid;
  max-width: 100%;
  min-width: 100%;
}
.modal-content-demo .inner-bottom a.cl1 {
  color: #0ea44a;
  border-color: #0ea44a;
}

#modalsucess .modal-content-demo {
  text-align: center;

}
#modalsucess .inner-bottom {
 text-align: center;
  padding: 0;
}

#modalsucess .inner-bottom a {
 color: #fff;
 
}

#modalsucess .modal-content-demo p {
  margin-bottom: 20px;
}

#modalsucess .modal-content-demo h4 {
  margin-top: 10px;
    margin-bottom: 20px;
}

#modalsucess .modal-content-demo .backlink {
  color: #BEC2C9;
  display: block;
  padding: 15px 0 0;
}


/* The Close Button */
.close-demo {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 15px;
  right: 10px;
}
.close-demo:hover,
.close-demo:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 1024px) {
  .innerCover-demo {
    width: 100%;
    margin: 0 auto;
    position: relative;
    background-color: #0ea44a;
  }
}


.hide {
  display: none;
}

.wrapContent-demo {
  overflow: hidden;
}

.logo-demo {
  font-size: 23px;
}

.bgrepeatTop-demo {
  z-index: 15;
}
.top-demo {
  z-index: 20;
    position: relative;
}

.bgrepeatTop-demo {
  height: 80px;
}

.bgScan {
  position: absolute;
  right: 96px;
  top: 195px;
  width: 128px;
  height: 128px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qrcodeimg {
  position: absolute;
  right: 0;
  top: 100px;
  z-index: 9;
  right: 200px;
  width: 320px;
}
.qrcodeimg img {
  display: block;
  width: 100%;
}
.qrcodeimg canvas {
  height: 120px !important;
  width: 120px !important;
}

.lightmb {
  display: none;

}
.lightmb img {
  max-width: 100%;
}
@media (min-width: 1400px) {
  .innerCover-demo {
    width: 80%;
  }
  .qrcodeimg {
       position: absolute;
      right: 0;
      top: 180px;
      z-index: 9;
      right: 240px;
  }

}

@media (min-width: 1700px) {
  .qrcodeimg {
       position: absolute;
      right: 0;
       top: 180px;
      z-index: 9;
      right: 284px;
  }
}


@media (max-width: 1300px) {
  .innerCover-demo {
    width: 80%;
  }
  .qrcodeimg {
    position: absolute;
    right: 0;
    top: 100px;
    z-index: 9;
    right: 120px;
  }
  .noti-demo {
    bottom: 60px;
  }

}
@media (max-width: 1100px) {
  .noti-demo {
    bottom: 0;
  }
}
@media (max-width: 1024px) {
  
 .innerCover-demo {
    width: 100%;
    height: 100%;
  }
  .modal-content-demo {
    width: 90%;
  }

  .bgCover-demo, .d-demo, .d1-demo, .hand-demo, .noti-demo {
    display: none;
  }

  .qrcodeimg{
    top: 320px;
    left: 50%;
    right: inherit;
    transform: translate(-50%, -50%);
  }

  
}


@media (max-width: 992px) {
  .noti-demo {
    right: 40%;
  }

  .noti2-demo {
    width: 45%;
  }
}


@media (max-width: 767px) {
 
  .qrcodeimg canvas {
      height: 120px !important;
      width: 120px !important;
  }

  .noti-demo {
    right: 15%;
  }

  .noti2-demo {
    width: 75%;
    right: 10%;
  }

  .logo-demo span {
    display: none;
  }

  .btn-demo a {
    padding: 3px 20px;
    margin-right: 15px;
  }

  .lightmb {
    display: block;
  
  }
}

@media (max-width: 320px) {
 .noti-demo {
    right: 14%;
  }

  .btn-demo a {
    padding: 3px 5px;
    margin-right: 15px;
    font-size: 11px;
  }

  

}

.help-step {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}
.help-step .step-item {
  text-align: center;
  width: 25%;
}
.help-step .step-item.item1 {width: 33%;}
.help-step .step-item.item2 {width: 66%;}
.help-step .step-item .item-inner{
  padding: 10px 10px;
  margin-bottom: 30px;
  position: relative;
}
.help-step .step-item img {
  margin-bottom: 10px;
  width: 100%;
  
}
.help-step .step-item .item-cover {
  position: relative;
}
.help-step .step-item .item-content {
  padding: 0 20px;
}
.help-step .step-item .step-img2 {
  max-width: 500px;
}
.help-step .step-item .step-img1 {
  max-width: 250px;
}
.help-step .step-item  .step-hand {
  position: absolute;
  width: 80px;
  right: 0;
  bottom: -50px;
  z-index: 1000;
}
.help-step .step-nums {
  font-size: 16px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  line-height: 24px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: #0ea44a;
  margin: 10px auto;
}
.help-step .step-title {
  font-size: 16px;
  text-align: center;
  color: #000;
  font-weight: bold;
  margin: 10px 0 5px 0; 
}
.help-step .step-desc {
  font-size: 14px;
  text-align: center;
  color: #000;
}

@media (max-width: 1200px) {
  .help-step .step-item.item1 {width: 33%;}
  .help-step .step-item.item2 {width: 66%;}
}

@media (max-width: 800px) {
  .help-step .step-item.item1 {width: 100%;}
  .help-step .step-item.item2 {width: 100%;}
}

@media (max-width: 600px) {
  .help-step .step-item.item1 {width: 100%;}
  .help-step .step-item.item2 {width: 100%;}
  .modal-content-demo {
    padding: 20px;
  }
  .help-step .step-item  .step-hand {
    position: absolute;
    width: 60px;
    right: -30px;
    bottom: -30px;
    z-index: 1000;
  }
}