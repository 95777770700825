.downloadpage header {
    background: url(src/assets/images/homev1/bg-feature.png) no-repeat top left;
    background-size: 100% 100%;
  }
  .downloadpage .btn-download {
    margin: 20px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .downloadpage .btn-download img {
    float: left;
  }
  .downloadpage .btn-download a {
    color: #0fa44a;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 40%;
    margin: 5px;
    flex: 0.5;
    font-size: 14px;
  }
  .downloadpage .btn-download.btn-col {
    flex-direction: row;
    margin: 0;
  }
  .downloadpage .btn-download a.btn-border {
    background-color: #fff;
    color: #0fa44a;
    border: 1px #0fa44a solid;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 5px 3px;
    flex: 1;
    font-size: 12px;
    width: 33.3%;
    padding: 0 5px;
  }
  .downloadpage .btn-download a.btn-border img {
    fill: #0fa44a;
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }
  .downloadpage.home-v1 .header-middle .txt {
    padding-top: 40px;
    padding-right: 0;
    text-align: center;
  }
  .downloadpage.home-v1 .header-middle .txt h6:before {
    left: 50%;
    transform: translateX(-50%);
  }
  .downloadpage.home-v1 .header-middle .txt p {
    margin-bottom: 30px;
    text-align: left;
  }
  .downloadpage.home-v1 .img-right {
    top: 150px;
  }
  .downloadpage.home-v1 .view-help {
    margin-top: 0;
    position: relative;
    padding: 0 25px;
    margin-bottom: 30px;
  }
  .downloadpage .header-middle .image {
    width: 70%;
    margin: 0 auto;
  }
  .downloadpage .group-download {
    justify-content: center;
    margin: 30px 0;
  }
  .downloadpage .group-download a {
    margin: 0 7px;
    transition: all 0.3s ease;
  }
  .downloadpage .group-download a:hover {
    filter: brightness(110%);
    transform: translateY(-5px);
  }
  .downloadpage .image2 {
    width: 30%;
    margin: 0 auto;
  }
  .downloadpage .inner-dl {
    padding: 100px 0;
  }
  .downloadpage .inner-dl .title-block {
    margin-top: -120px;
    margin-bottom: 20px;
  }
  .downloadpage .title-block span.title {
    font-weight: bold;
  }
  @media (max-width: 992px) {
    /* .downloadpage.home-v1 .img-right {
      top:0;
      display: none;
    } */
    .downloadpage .image2 {
      width: 50%;
      margin: 0 auto;
    }
    .downloadpage .image2 {
      width: 90%;
      margin: 0 auto;
    }
    .downloadpage .inner-dl {
      padding: 30px 0;
    }
    .downloadpage .btn-download.btn-col {
      flex-direction: column;
    }
    .downloadpage .btn-download a.btn-border {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      flex: 1;
      font-size: 13px;
      width: 100%;
      padding: 0 5px;
      margin: 2px 0;
    }
    .downloadpage .btn-download a.btn-border img {
      top: 0;
      display: block;
    }
  }
  @media (max-width: 768px) {
    .downloadpage.home-v1 .img-right {
      top:150px;
      display: none;
    }
    .downloadpage .btn-download.btn-col {
      flex-direction: column;
    }
    .downloadpage .btn-download a.btn-border {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      flex: 1;
      font-size: 13px;
      width: 90%;
      padding: 0 5px;
      margin: 2px 0;
    }
    .downloadpage .btn-download a.btn-border img {
      top: 0;
      display: block;
    }
  }
  
  @media (max-width: 600px) {
    .downloadpage.home-v1 .header-middle {
      padding-bottom: 30px;
    }
    .downloadpage.home-v1 .header-middle .txt {
      padding-top: 20px;
    }
    .downloadpage.home-v1 .img-right {
      top: 0;
      right: -15px;
      margin-top: 40px;
    }
    .downloadpage header {
      background-position: center center;
      background-size: cover;
      background-color: #0fa44a;
      margin-bottom: 30px;
    }
  
    .downloadpage.home-v1 .header-middle {
      margin-top: 0;
    }
  }