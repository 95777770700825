.mb-100 {
    margin-bottom: 100px;
}

.mb-70 {
    margin-bottom: 70px;
}

.mb-50 {
    margin-bottom: 50px;
}

.pt-70 {
    padding-top: 70px;
}

.pb-70 {
    padding-bottom: 70px;
}

.block-Gray {
    background: #F5F5F5;
}

.block-Gray {
    background: #F5F5F5;
}

.block-feature .section {
    padding: 25px;
}

.block-feature .section.active {
    background: #F5F5F5;
}

.block-feature .section1 {
    padding: 25px;
}

.block-feature .section1.active {
    background: #ffffff;
}

.title-feature-page {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 30px;
    color: #333333;
}

.txt-block-feature h6 {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

.feature-page .header-middle .txt h6:before {
    left: 50%;
    transform: translateX(-50%);
}

.feature-page .feature-page-container:before {
    content: "";
    background: url(src/assets/images/feature/gr_decorate.png) no-repeat top left;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 550px;
    width: 100%;
}

.feature-page .header-middle {
    margin-top: 80px;
}

.tab-feautre {
    margin-top: -95px;
    z-index: 9;
}

.tab-feautre ul {
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    padding: 0 15px;
    justify-content: center;
}

.tab-feautre ul li a {
    display: inline-block;
    padding: 19px 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 17px;
    color: #616770;
}

.tab-feautre ul li a.active {
    color: #0FA44A;
    border-bottom: #0FA44A solid 3px;
}

.slideFeature {
    position: relative;
}

.slideFeature .innerslide {
    position: absolute;
    top: 0px;
    width: 297px;
    left: 0px;
}

.iphone {
    width: 297px;
}

.slideFeature .owl-dots {
    position: absolute;
    bottom: -30px;
    left: 50%;
    z-index: 99;
    transform: translate(-50%);
}

.slideFeature .owl-theme .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
}

.slideFeature .owl-theme .owl-dots .owl-dot.active span,
.slideFeature .owl-theme .owl-dots .owl-dot:hover span {
    background-color: #0FA44A;
}

.block-feature-4 .owl-dots,
.block-feature-5 .owl-dots {
    opacity: 0;
}

.iphone {
    position: relative;
    /* z-index: 9; */
}

.newsletter-block {
    background: #0FA44A;
    padding: 75px 0;
}

.inner-newsletter-block {
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size: 13px;
    line-height: 22px;
}

.inner-newsletter-block p {
    font-weight: bold;
    margin-right: 35px;
}

.form-newsletter {
    display: flex;
    position: relative;
}

.form-newsletter .btn-submit {
    position: absolute;
    color: #fff;
    width: 150px;
    height: 37px;
    top: 4px;
    right: 5px;
    border-radius: 5px;
    text-transform: none !IMPORTANT;
    line-height: 37px;
}

.inner-newsletter-block .form-control {
    border-radius: 5px !important;
    width: 760px;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (max-width: 1200px) {
    .slideFeature .innerslide {
        position: absolute;
        top: 0px;
        width: 240px;
        left: 0px;
    }
    .iphone {
        width: 240px;
    }
}

@media (max-width: 992px) {
    .slideFeature {
        overflow: hidden;
    }
    .slideFeature .innerslide {
        position: absolute;
        top: 0px;
        width: 175px;
        left: 0px;
    }
    .iphone {
        width: 175px;
    }
    .inner-newsletter-block .form-control {
        width: 450px;
    }
}

@media (max-width: 767px) {
    .block-feature-1 {
        padding-top: 70px;
    }
    .mb-100 {
        margin-bottom: 70px;
    }
    .block-feature .row>div+div {
        margin-top: 40px;
    }
    /* 
    .slideFeature .innerslide {
        position: absolute;
        top: 20px;
        width: 215px;
        left: 25px;
    } */
    .feature-page header .container:before {
        display: none;
    }
    .inner-newsletter-block {
        flex-direction: column;
    }
    .inner-newsletter-block .form-control {
        width: 100%;
    }
    .inner-newsletter-block p {
        margin-bottom: 15px;
        text-align: center;
        font-size: 17px;
    }
    .form-newsletter {
        flex-direction: column;
    }
    .form-newsletter .btn-submit {
        /* background: #fff !important; */
        border: 1px solid #fff;
        width: 100%;
        margin-top: 15px !important;
        filter: brightness(110%);
        position: relative;
        right: 0;
    }
    .feature-page .header-middle {
        margin-top: 50px;
    }
    .tab-feautre {
        margin-top: 15px;
    }
    .tab-feautre ul {
        flex-direction: column;
    }
}

@media (max-width: 550px) {
    .container .col-sm-5 {
        display: flex;
        justify-content: center;
    }
    .slideFeature .innerslide {
        position: absolute;
        top: 0px;
        width: 237px;
        left: 0px;
    }
    .iphone {
        width: 237px;
    }
}

@media (max-width: 400px) {
    .slideFeature .innerslide {
        position: absolute;
        top: 0px;
        width: 213px;
        left: 0px;
    }
    .iphone {
        width: 213px;
    }
}

@media (max-width: 320px) {
    .slideFeature .innerslide {
        position: absolute;
        top: 0px;
        width: 170px;
        left: 0px;
    }
    .iphone {
        width: 170px;
    }
}